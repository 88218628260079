import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="h1" components={components}>{`Full List of Films`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/screen-shot-2020-04-21-at-6.51.18-pm.png","alt":null,"title":"Full List"}}></MDXTag></MDXTag>
<MDXTag name="h1" components={components}>{`Clips From Films Referenced Directly By Welles and His Team`}</MDXTag>
<MDXTag name="h2" components={components}>{`Congorilla (1932)`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="2027/fulcrum.ws859h78j" title="Congorilla%20-%20Wilderbeasts%20Running%20in%20Silhouette%20" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Animals running in silhouette`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="2027/fulcrum.n870zs62h" title="Congorilla%20-%20Crocodiles" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Crocodiles on the river`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.xg94hr57n"}}>{`https://hdl.handle.net/2027/fulcrum.xg94hr57n`}</MDXTag>{`" title="Congorilla%20-%20Elephants" width="615"`}</MDXTag>
<MDXTag name="p" components={components}>{`Elephants on the river`}</MDXTag>
<MDXTag name="h2" components={components}>{`Baboona (1935)`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.pn89d8233"}}>{`https://hdl.handle.net/2027/fulcrum.pn89d8233`}</MDXTag>{`" title="Baboona%20Crocodiles" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Crocodiles in Baboona`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.z603r045x"}}>{`https://hdl.handle.net/2027/fulcrum.z603r045x`}</MDXTag>{`" title="Baboona%20-%20Giraffes" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Giraffes in Baboona`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.jd472z589"}}>{`https://hdl.handle.net/2027/fulcrum.jd472z589`}</MDXTag>{`" title="Baboona%20-%20Baboons" width="632"`}</MDXTag>
<MDXTag name="p" components={components}>{`Baboons in Baboona`}</MDXTag>
<MDXTag name="h2" components={components}>{`Sanders of the River (1935)`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.ks65hf13t"}}>{`https://hdl.handle.net/2027/fulcrum.ks65hf13t`}</MDXTag>{`" title="Sanders%20-%20Crocodiles" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Crocodiles in Sanders of the River`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.kw52jb11h"}}>{`https://hdl.handle.net/2027/fulcrum.kw52jb11h`}</MDXTag>{`" title="Sanders%20-%20River%20Center" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Shot from river center`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.6h440v346"}}>{`https://hdl.handle.net/2027/fulcrum.6h440v346`}</MDXTag>{`" title="Sanders%20-%20Medicine%20Man" width="628"`}</MDXTag>
<MDXTag name="p" components={components}>{`Medicine man in Sanders of the River`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.5h73pz07h"}}>{`https://hdl.handle.net/2027/fulcrum.5h73pz07h`}</MDXTag>{`" title="Sanders%20-%20Village%20Dance" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Village dance in Sanders of the River`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.2227mr68p"}}>{`https://hdl.handle.net/2027/fulcrum.2227mr68p`}</MDXTag>{`" title="Sanders%20-%20Chant%20Dance" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Chant Dance`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.6395w923p"}}>{`https://hdl.handle.net/2027/fulcrum.6395w923p`}</MDXTag>{`" title="Sanders%20-%20Saluting%20Canoes" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Native tribes saluting from canoes`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.6h440v35g"}}>{`https://hdl.handle.net/2027/fulcrum.6h440v35g`}</MDXTag>{`" title="Sanders%20-%20Drums" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Drums`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.6h440v35g"}}>{`https://hdl.handle.net/2027/fulcrum.6h440v35g`}</MDXTag>{`" title="Sanders%20-%20Boat%20Long%20Shot" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Boat 1`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.cr56n2903"}}>{`https://hdl.handle.net/2027/fulcrum.cr56n2903`}</MDXTag>{`" title="Sanders%20-%20Boat%20Long%20Shot%202" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Boat 2`}</MDXTag>
<MDXTag name="h2" components={components}>{`The Four Feathers (1939)`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.bv73c227x"}}>{`https://hdl.handle.net/2027/fulcrum.bv73c227x`}</MDXTag>{`" title="The%20Four%20Feathers%20-%20Hippos" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Hippos`}</MDXTag>
<MDXTag name="h2" components={components}>{`Suez (1938)`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.ww72bd31w"}}>{`https://hdl.handle.net/2027/fulcrum.ww72bd31w`}</MDXTag>{`" title="Suez%20-%20Minarets" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Minarets 1`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.3x816p399"}}>{`https://hdl.handle.net/2027/fulcrum.3x816p399`}</MDXTag>{`" title="Suez%20-%20Minarets%202" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Minarets 2`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.08612q663"}}>{`https://hdl.handle.net/2027/fulcrum.08612q663`}</MDXTag>{`" title="Suez%20-%20Minarets%203" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Minarets 3`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.s7526f500"}}>{`https://hdl.handle.net/2027/fulcrum.s7526f500`}</MDXTag>{`" title="Suez%20-%20Desert" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Desert`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.pn89d824c"}}>{`https://hdl.handle.net/2027/fulcrum.pn89d824c`}</MDXTag>{`" title="Suez%20-%20Key%20Processes" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Miniatures and key processes`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://hdl.handle.net/2027/fulcrum.hd76s210h"}}>{`https://hdl.handle.net/2027/fulcrum.hd76s210h`}</MDXTag>{`" title="Suez%20-%20Explosion" width="640"`}</MDXTag>
<MDXTag name="p" components={components}>{`Explosion`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Database of Films Welles Watched","key":"the-database-process","parentKey":"database-of-films-welles-watched","sortOrder":0};

  